.slide-in-enter-right {
    transform: translateX(100%);
    opacity: 0;
  }
  
  .slide-in-enter-right-active {
    transform: translateX(0);
    opacity: 1;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }

  .slide-in-enter-left {
    transform: translateX(-100%);
    opacity: 0;
  }
  
  .slide-in-enter-left-active {
    transform: translateX(0);
    opacity: 1;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }

  .sidebar-enter {
    right: -300px;
  }
  
  .sidebar-enter-active {
    right: 0;
    transition: right 300ms ease-in-out;
  }
  
  .sidebar-exit {
    right: 0;
  }
  
  .sidebar-exit-active {
    right: -300px;
    transition: right 300ms ease-in-out;
  }

  /* Dropdown Menu */

  .custom-submenu {
    display: none;
  }
  .custom-submenu2 {
    display: none;
  }

  li:hover > .custom-submenu {
    display: block;
  }
  
  li:hover > .custom-submenu2 {
    display: block;
  }