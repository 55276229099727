.custom-table-container > tbody > tr {
  border-bottom: 1px solid #ececec;
}
.custom-table-container > tbody > tr > td {
  padding: 1rem 2rem;
}
.custom-table-container > tbody > tr:nth-child(odd) {
  background-color: #f9f9f9;
}
.custom-table-container > tbody > tr:nth-child(even) {
  background-color: white;
}
.custom-table-container > tbody > tr:hover {
  background-color: #f6f8fa;
}

@media only screen and (max-width: 639px) {
  .custom-table-container > tbody > tr > td {
   padding: 0.8rem 0.5rem;
  }
}