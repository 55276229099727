.custom-bg-container {
    --bg-image-opacity: 0.6;
    position: relative;
    isolation: isolate;
}

.custom-bg-container::after {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    background: black;
    opacity: var(--bg-image-opacity);
}